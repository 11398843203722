// Dependency
import React, { useEffect, useState } from "react"
import Breadcrumbs from "../components/general/breadcrumbs/breadcrumbs"
import cx from "classnames"
import { useTranslation } from "react-i18next"

const INGRID_SITE_ID = "faf06b6d-1174-49ed-988a-b7615021fe44"

const TrackOrder = () => {
  const [ingridLoaded, setIngridLoaded] = useState(false)

  const containerId = "delivery-tracking-widget"

  const trackingLocales = {
    en: "en-US",
    gb: "en-GB",
    fr: "fr-FR",
    da: "da-DK",
    nl: "nl-NL",
    fi: "fi-FI",
    sv: "sv-SE",
    de: "de-DE",
    no: "nb-NO",
  }

  const addTrackingWidget = locale => {
    console.log(window.IngridDeliveryTrackingWidgetApi)
    if (window.IngridDeliveryTrackingWidgetApi) {
      window.IngridDeliveryTrackingWidgetApi.renderSearchMode({
        containerId,
        siteId: INGRID_SITE_ID,
        locale,
      })
      //setIngridLoaded(true)
    } else {
      setIngridLoaded("again")
    }
  }

  const clearContainer = container => {
    while (container?.hasChildNodes()) {
      if (container.firstChild) {
        container.removeChild(container.firstChild)
      }
    }
  }

  useEffect(() => {
    const container = document.getElementById(containerId)

    if (!window || !container || ingridLoaded === true) return
    clearContainer(container)
    addTrackingWidget(
      trackingLocales[process.env.GATSBY_BUILD_LANGUAGE] || "en-US"
    )

    return () => {
      clearContainer(container)
    }
  }, [addTrackingWidget, ingridLoaded])
  
  const { t } = useTranslation()

  return (
    <section className="grid-container">
      <Breadcrumbs breadTitle="Track order" whiteBackground={true} />
      <div className="grid__row">
        <div className="grid__col-xlarge-shift-4 grid__col-xlarge-4 grid__col-large-6 grid__col-medium-9 grid__col-small-2">
          <section
            className={cx("text-block", {
              "text-block--center": true,
            })}
            id="text-block"
          >
            <h1>{t("trackOrder.heading")}</h1>
            <p> {t("trackOrder.text")}</p>
          </section>
          <div id={containerId}></div>
        </div>
      </div>
    </section>
  )
}

export default TrackOrder

export const Head = () => <title>Tracking Tanrevel</title>
